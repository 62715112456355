import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import WalkthroughCZ from '../components/WalkthroughCZ';
import WalkthroughDE from '../components/WalkthroughDE';
import Head from 'next/head';
import { useDispatch } from 'react-redux';
import { setNoFreeSantas, setSantasCount } from '../store/slices/walkthroughSlice';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getSantasCount } from '../utils/getSantasCount';

export default function Home() {
    const { i18n  } = useTranslation('common');
    const locale = i18n.language;
    const router = useRouter();
    const { query } = router;
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchFreeSantasCount = async () => {
            try {
                const result = await getSantasCount();
                if (result.status) {
                    if (result.count > 0) {
                        dispatch(setNoFreeSantas(false));
                        dispatch(setSantasCount(result.count));
                    }
                }
            } catch (err) {
                console.error(err);
            }

            if (typeof query['no-santa'] !== 'undefined') {
                dispatch(setNoFreeSantas(true));
            } 
            if (typeof query['free-santas'] !== 'undefined') {
                dispatch(setNoFreeSantas(false));
            }
        };
        fetchFreeSantasCount();
    }, [query]);

    if (locale === 'cs') {
        return <WalkthroughCZ />;
    } else {
        return (<>
            <Head>
                <title>Das zarteste Geschenk: unser Milka Weihnachtsmann</title>
                <meta name="description" content="Schenk einen Milka Weihnachtsmann mit deiner zarten Nachricht – das zarteste Geschenk für deine Liebsten zu Weihnachten!" />
                <meta property="og:title" content="Das zarteste Geschenk: unser Milka Weihnachtsmann" />
                <meta property="og:description" content="Schenk einen Milka Weihnachtsmann mit deiner zarten Nachricht – das zarteste Geschenk für deine Liebsten zu Weihnachten!" />
                <meta property="og:image" content={`${process.env.NEXT_PUBLIC_URL}/images/og-image.jpg`} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Head>
            <WalkthroughDE />
        </>);
    }
}

export async function getStaticProps({ locale }) {
    return {
        props: {
            ...(await serverSideTranslations(locale, ['common'])),
        },
    };
}