import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateForm } from '../../store/slices/milkulasSlice';
import { setScreen, setIsBlurred } from '../../store/slices/walkthroughSlice';
import CheckboxInput from '../form/CheckboxInput';
import Guide_DE from "./Guide_DE";
import CountDown from './parts/CountDown';

export default function Screen_DE_A_start({ onNext }) {
    const dispatch = useDispatch();
    const [currentStep, setCurrentStep] = useState('a');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const noFreeSantas = useSelector((state) => state.walkthrough.noFreeSantas);
    
    function smoothScrollTo(top, duration) {
        const start = window.scrollY;
        const change = top - start;
        const startTime = performance.now();
      
        function animateScroll(currentTime) {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / duration, 1);
            window.scrollTo(0, start + change * progress);
            if (progress < 1) {
                requestAnimationFrame(animateScroll);
            }
        }
      
        requestAnimationFrame(animateScroll);
    }

    const handleCreateMessage = () => {
        setCurrentStep('b');
        smoothScrollTo(0, 300);
        dispatch(setIsBlurred(true));
    };

    /* form fc - START */
    const [form, setForm] = useState({
        agreeToTerms: false,
    });
    const [validity, setValidity] = useState({
        agreeToTerms: false, 
    });
    const isValid = Object.values(validity).every(Boolean);
    const setValid = (field, isValidField) => {
        setValidity((prevValidity) => ({
            ...prevValidity,
            [field]: isValidField,
        }));
    };
    const handleChange = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));
    };
    const handleSubmit = async (callbackScreen, withAudio) => {
        if (isValid) {
            dispatch(updateForm(form));
            try {
                const settings = withAudio ? { video: true, audio: true } : { video: true };
                const stream = await navigator.mediaDevices.getUserMedia(settings);
                dispatch(setScreen(callbackScreen));
            } catch (error) {
                dispatch(setScreen("A-no_video"));
            }
        }
        setIsSubmitted(true);
    };
    /* go through - END */

    return (<>  
        <div className="fit-house-bg make_header_space">
            <div className="content-wrapper" data-step={currentStep}>
                <div className="step_a">
                    <p className="text-big mb-[1em] shrink">Wem möchtest Du dieses Weihnachten eine Freude bereiten?</p>
                    <h1 className="mb-[0.2em] shrink">GEBEN IST DAS SCHÖNSTE GESCHENK</h1>
                    { !noFreeSantas ? (
                        <p className="text-big mb-[1em] shrink"><strong>Sende Deine Weihnachtsgrüße und verschenke einen Milka Weihnachtsmann mit unserer Cashback Aktion.*</strong></p>
                    ):(
                        <p className="text-big mb-[1em] shrink"><strong>Sende Deine Weihnachtsgrüße und verschenke einen Milka Weihnachtsmann.*</strong></p>
                    )}
                    <button className="btn btn-gold shrink" onClick={handleCreateMessage}>ERSTELLE JETZT DEINE NACHRICHT</button>
                </div>
                <div className="step_b">
                    <h1 className='shrink'>WIE MÖCHTEST DU DEINE ZARTE NACHRICHT VERSENDEN?</h1>
                    <CheckboxInput
                        id="agreeToTerms"
                        label="Ich stimme den <a target='_blank' class='underline' href='/pdf/Allgemeine_geschaftsbedingungen.pdf'>Teilnahmebedingungen</a> zu "
                        checked={form.agreeToTerms}
                        onChange={(value) => handleChange('agreeToTerms', value)}
                        onValidated={(isValidField) => setValid('agreeToTerms', isValidField)}
                        isSubmitted={isSubmitted}
                        required={true}
                        customErrorMessage="Zustimmung erforderlich"
                    />
                </div>
                <div className="milkulas_wrapper">
                    <div className="bubble step_b">
                        <img className='bubble-icon' src="/images/icons/icon_photo.png" alt="photo"/>
                        <button className="btn btn-gold btn-gold-icon" onClick={() => handleSubmit("B-photo", false)}><span className="icon"><img  src="/images/icons/icon_photo_clean.png" alt="photo"/></span><span className="text">Foto aufnehmen</span></button>
                    </div>
                    { currentStep === 'a' && 
                        <div className="milkulas">
                            <div className="bubble-wrapper">
                                { noFreeSantas ? (
                                        <div className='no-santa'><p>Diese Woche haben bereits alle Milka Weihnachtsmänner ein Zuhause gefunden. Schau nächste Woche wieder vorbei, um eine Einladung zur Cashback Aktion zu verschicken.</p><p>Du kannst aber weiterhin eine zarte Nachricht an deine Liebsten versenden.</p></div>
                                    ) : <CountDown/>
                                }
                            </div>
                            <img  src="/images/santa.png" alt="santa"/>
                        </div>
                    }
                    { currentStep === 'b' &&  <>
                        <span className='button-divider'>oder</span>
                        <img className="milkulas_m" src="/images/santa.png" alt="santa"/>
                        <img className="milkulas" src="/images/santa_b.png" alt="santa"/>
                    </> }
                    
                    <div className="bubble step_b">
                        <img className='bubble-icon' src="/images/icons/icon_video.png" alt="video"/>
                        <button className="btn btn-gold btn-gold-icon" onClick={() => handleSubmit("B-video", true)}><span className='icon'><img src="/images/icons/icon_video_clean.png" alt="video"/></span><span className='text'>Video aufnehmen</span></button>
                    </div>
                </div>
            </div>
        </div>
        { currentStep === 'a' &&  <Guide_DE /> }
        { currentStep === 'a' &&  <>
            <div className='flex items-center justify-center mb-16 md:mb-[150px] px-5'>
                <button className="btn btn-gold" onClick={handleCreateMessage}>ERSTELLE JETZT DEINE NACHRICHT</button>
            </div>
            <p className='!text-base md:!text-xl text-center'>*Nur bei teilnehmenden Handelspartnern und nur solange der Vorrat reicht.</p>
        </>}
    
    </>);
}