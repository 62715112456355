import { useSelector, useDispatch } from 'react-redux';
import { setScreen } from '../store/slices/walkthroughSlice';
import Cookies from 'js-cookie';

/* TEMP dev - START */
import { useRouter } from 'next/router';
import { useEffect } from 'react';
/* TEMP dev - END */

import Screen_DE_comming_soon from './walkthrough/Screen_DE_comming_soon';
import Screen_DE_A_start from './walkthrough/Screen_DE_A_start';
import Screen_DE_B_photo from './walkthrough/Screen_DE_B_photo';
import Screen_DE_B_video from './walkthrough/Screen_DE_B_video';
import Screen_DE_C_preview from './walkthrough/Screen_DE_C_preview';
import Screen_DE_no_video from './walkthrough/Screen_DE_no_video';
import Screen_DE_other_boothTYP from './walkthrough/Screen_DE_other_boothTYP';
import Screen_DE_nsfw_content from './walkthrough/Screen_DE_nsfw_content';
import Screen_DE_A_start_booth from './walkthrough/Screen_DE_A_start_booth';

export default function WalkthroughDE() {
    const currentScreen = useSelector((state) => state.walkthrough.currentScreen);
    const isBlurred = useSelector((state) => state.walkthrough.isBlurred);
    const dispatch = useDispatch();

    const handleSetScreen = (screen) => {
        dispatch(setScreen(screen));
    };
    
    /* TEMP dev - START */
    const router = useRouter();
    useEffect(() => {
        if (router.isReady) {
            const { screen, booth } = router.query;
            if (screen) {
                dispatch(setScreen(screen));
            }
            if (booth) {
                dispatch(setScreen("booth-start"));
            }
        }
        if (Cookies.get('xbooth')) {
            dispatch(setScreen("booth-start"));
        }
    }, [router.isReady, router.query, dispatch]);
    /* TEMP dev - END */

    let ScreenComponent;
    switch (currentScreen) {
        case 'X':
            ScreenComponent = (
                <Screen_DE_comming_soon/>
            );
            break;
        case 'A':
            ScreenComponent = (
                <Screen_DE_A_start/>
            );
            break;
        case 'A-no_video':
            ScreenComponent = (
                <Screen_DE_no_video/>
            );
            break;
        case 'B-photo':
            ScreenComponent = (
                <Screen_DE_B_photo/>
            );
            break;
        case 'B-video':
            ScreenComponent = (
                <Screen_DE_B_video/>
            );
            break;
        case 'C-preview':
            ScreenComponent = (
                <Screen_DE_C_preview/>
            );
            break; 
        case 'booth-start':
            ScreenComponent = (
                <Screen_DE_A_start_booth/>
            );
            break; 
        case 'booth-typ':
            ScreenComponent = (
                <Screen_DE_other_boothTYP/>
            );
            break; 
        case 'nsfw':
            ScreenComponent = (
                <Screen_DE_nsfw_content/>
            );
            break; 
        default:
            ScreenComponent = (
                <Screen_DE_A_start/>
            );
            break;
    }

    return (
        <main className={`walkthrough_de current-screen-${currentScreen} ${ isBlurred && 'blurred' }`}>
            <div className={`main_bg current-screen-${currentScreen}`}>
                <span className="main_bg-tree_left"></span>
                <span className="main_bg-tree_right"></span>
            </div>
            <div className="screen-wrapper">{ScreenComponent}</div>
        </main>
    );
}