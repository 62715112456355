import React from 'react';
import { useSelector } from 'react-redux';

export default function  CountDown() {
    const santasCount = useSelector((state) => state.walkthrough.santasCount);
    const countStr = typeof santasCount === 'number' ? santasCount.toString() : '0';
    const digits = countStr.split('');

    return (
        <div className='count-down'>
            <div className='count-down--text'>Diese Woche noch</div>
            <div className='count-down--inner'>
                {digits.map((digit, index) => (
                    <span key={index}>{digit}</span>
                ))}
            </div>
            <div className='count-down--text'>Einlösungen!</div>
        </div>
    );
};