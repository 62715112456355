import { useSelector } from 'react-redux';

export default function Guide_DE() {
    const noFreeSantas = useSelector((state) => state.walkthrough.noFreeSantas);
    const title = noFreeSantas ? "3 einfache Schritte, um Deine Nachricht zu erstellen." : "3 einfache Schritte, um deine Nachricht zu erstellen und die Einladung zur Cashback Aktion zu versenden";

    return (
        <div className="steps">
            <h2 className="text-3xl md:text-4xl 2xl:text-5xl max-w-[1280px] mb-14 mx-auto">{title}</h2>
            <div className="steps-items">
                <div className="steps-item">
                    <img src="/images/icons/guide_step-1.png" alt="Write"/>
                    <span>Wähle ein Format</span>
                    <p>Nimm ein Foto oder Video auf</p>
                </div>
                <div className="steps-divider">
                    <img src="/images/ui/arrow.png"></img>
                </div>
                <div className="steps-item">
                    <img src="/images/icons/guide_step-2.png" alt="Create"/>
                    <span>Sei kreativ</span>
                    <p>Füge festliche Sticker und Musik hinzu</p>
                </div>
                <div className="steps-divider">
                    <img src="/images/ui/arrow.png"></img>
                </div>
                <div className="steps-item">
                    <img src="/images/icons/guide_step-3.png" alt="Share"/>
                    <span>Teile Deine  Nachricht</span>
                    <p>Sende Deine Weihnachtsgrüße an Deine Liebsten</p>
                </div>
            </div>
        </div>
    );
}